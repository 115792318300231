/**
 * Init Swiper
 */
const carousel = new Swiper('.carousel', { //eslint-disable-line
  spaceBetween: 30,
  effect: 'fade',
  centeredSlides: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
});


/**
 * Handdle reveal head navigation
 */
const revealHead = () => {
  const head = document.getElementById('head-home');

  const replace = document.getElementById('home-info');
  const positionReplace = replace.getBoundingClientRect().top;

  // eslint-disable-next-line
  positionReplace <= 0
    ? head.classList.add('head--reveal')
    : head.classList.remove('head--reveal');
};
window.addEventListener('scroll', revealHead);

/**
 * Get position from header and apply scroll animation
 */
const updatedDimensions = () => {
  const offset = document.getElementById('carousel');
  const { offsetHeight } = offset;

  const height = window.pageYOffset || document.documentElement.scrollTop;

  // eslint-disable-next-line
  height > 140
    ? offset.classList.add('carousel--overflow-one')
    : offset.classList.remove('carousel--overflow-one');

  // eslint-disable-next-line
  height > (offsetHeight - 320)
    ? offset.classList.add('carousel--overflow-two')
    : offset.classList.remove('carousel--overflow-two');
};
window.addEventListener('scroll', updatedDimensions);


/**
 * Taking an element position and checking if
 * the item is being visible on the screen
 */
const visibilityTimeline = () => {
  const element = document.getElementById('timeline').getBoundingClientRect().top;
  const receptor = document.getElementById('home__timeline-navigation');

  // eslint-disable-next-line
  element <= 0
    ? receptor.classList.add('home__timeline-navigation--overflow')
    : receptor.classList.remove('home__timeline-navigation--overflow');
};
window.addEventListener('scroll', visibilityTimeline);


/**
 * Set Attribute data in tag a and
 * init smooth scroll
 */
const nodeHash = document.getElementById('menu-timeline');
const tagsHash = nodeHash.querySelectorAll('a.nav-link');

tagsHash.forEach((elem) => {
  elem.setAttribute('data-scroll', '');
});


const menu = document.querySelectorAll('[data-scroll]');
console.log(menu);

// eslint-disable-next-line
const smoothScroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
  easing: 'easeInCubic',
  offset: 0,
  updateURL: false,
  emitEvents: true,
  callbackBefore (toggle, anchor) {
    //
  },
  callbackAfter (toggle, anchor) {
    menu.classList.remove('active');
    toggle.classList.add('active');
  },
});

console.log(smoothScroll);
